import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './video-gallery.css';
import { VideoComponent } from '../../components/video';
import { ALL_VIDEOS } from './VideoData';
import { generateVideoRows } from './generateVideoRows';

const VideoGallery = () => {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [visibleVideos, setVisibleVideos] = useState(6); // Start by showing only 6 videos

  const handleScroll = () => {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      setVisibleVideos((prev) => Math.min(prev + 6, ALL_VIDEOS.length));
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleThumbnailClick = (video) => {
    setSelectedVideo(video);
  };

  const handleClose = () => {
    setSelectedVideo(null);
  };

  // Usage
  const visibleRows = generateVideoRows(ALL_VIDEOS.slice(0, visibleVideos));

  return (
    <div className="video-gallery-wrapper">
      {visibleRows.map((row, rowIndex) => (
        <div className="video-row" key={`row-${rowIndex}`}>
          {row.map((video) => (
            <div key={video.id}>
              <motion.div
                className={`thumbnail ${video.type}`}
                whileHover={{ scale: 1.1 }}
                onClick={() => handleThumbnailClick(video)}
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{
                  opacity: 1,
                  scale: 1,
                  transition: { duration: 0.3 },
                }}
                exit={{ opacity: 0, scale: 0.8, transition: { duration: 0.3 } }}
                layoutId={video.id}
              >
                <img
                  src={video.thumbnail}
                  alt={video.title}
                  className="thumbnail-img"
                  loading="lazy"
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 100 100"
                  width="40px"
                  height="40px"
                  className="play-button-thumbnail"
                >
                  <polygon points="30,20 80,50 30,80" fill="#FFFEF5" />
                </svg>
              </motion.div>

              <div className="video-content-wrapper">
                <div className="video-content-title">{video.title}</div>
                <div className="video-content-category">{video.category}</div>
              </div>
            </div>
          ))}
        </div>
      ))}
      <AnimatePresence>
        {selectedVideo && (
          <motion.div
            className="expanded-video"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.5 } }}
            exit={{ opacity: 0, transition: { duration: 0.3 } }}
            layoutId={selectedVideo.id}
          >
            <motion.button
              onClick={handleClose}
              className="close-button"
              whileHover={{ scale: 1.2, rotate: 90 }}
              whileTap={{ scale: 0.9 }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                viewBox="0 0 24 24"
                width="20px"
                height="20px"
              >
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </motion.button>
            <VideoComponent
              showControls
              isLandscape={selectedVideo.type === 'landscape'}
              src={selectedVideo ? selectedVideo.url : ''}
              poster={selectedVideo.thumbnail}
              isMuted={false}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default VideoGallery;
